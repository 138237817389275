import 'photoswipe/dist/photoswipe.css'
import 'photoswipe/dist/default-skin/default-skin.css'

import styled from '@emotion/styled'
import React, { memo } from 'react'
import { Item } from 'react-photoswipe-gallery'

export interface Props {
  className?: string
  original: string
  thumbnail?: string
  alt?: string
  width: number
  height: number
}

export const Image = memo(function Image({
  className,
  original,
  thumbnail,
  alt,
  width,
  height,
}: Props) {
  return (
    <Container className={className}>
      <Item
        original={original}
        thumbnail={thumbnail}
        title={alt}
        width={width}
        height={height}
      >
        {({ ref, open }) => (
          <Inner
            ref={ref as React.RefObject<HTMLDivElement>}
            onClick={open}
            style={{
              backgroundImage: `url(${thumbnail})`,
            }}
          />
        )}
      </Item>
    </Container>
  )
})

const Container = styled.div`
  display: none;
  width: calc(25% - 0.125rem);
  cursor: pointer;
  overflow: hidden;
  margin-right: 0.125rem;
  margin-bottom: 0.125rem;
  padding-bottom: 19%;
  position: relative;
  &.visible {
    display: block;
  }
  > div {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    .lazyload-placeholder {
      height: 100%;
      background: ${({ theme }) => theme.colors.variants.neutralLight3};
    }
  }

  @media (max-width: 1599px) {
    width: calc(33.333% - 0.125rem);
    padding-bottom: 26.35%;
  }
`

const Inner = styled.div`
  width: 100%;
  height: 100%;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  transform: scale(1.1);
  transition: 0.2s ease-out;
  &:hover {
    transform: scale(1);
  }
`
